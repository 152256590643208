<template>
  <!--
    Display information about the network adapters and what they sent and recieved.
  -->
  <Portlet
    :title="$t('networkAdapterInformationComponent.networkAdapterInformation')"
    icon="network-wired"
    class="networkAdapterInformation"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getAnalyseReport()"
      />
    </template>

    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <table class="defaultTable w-100">
        <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th class="only-screen-lg">
              {{ $t('description') }}
            </th>
            <th class="only-screen-xxxl">
              {{ $t('networkAdapterInformationComponent.id') }}
            </th>
            <th class="buttonTD">
              {{ $t('options') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(networkInterface, index) in analysis"
            :key="`tableRow-networkInterface-${ index }`"
          >
            <th>{{ networkInterface.name }}</th>
            <td class="only-screen-lg">
              {{ networkInterface.description }}
            </td>
            <td class="only-screen-xxxl">
              {{ networkInterface.id.replace('{', '').replace('}', '') }}
            </td>
            <td class="buttonTD">
              <button
                class="btn btn-sm btn-primary btn-block"
                @click="openSidebar(index)"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="info-circle"
                />
                <span>
                  {{ $t('details') }}
                </span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <Sidebar
        :show-sidebar="sidebarAvailable"
        @close="closeSidebar"
      >
        <table
          v-if="sidebarAvailable"
          class="defaultTable"
        >
          <tbody>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.recieved') }}</th>
              <td>{{ numberConversionAdapter_bytes(analysis[selectedIndex].ipInterfaceStatistics.bytesReceived).bytes }} {{ numberConversionAdapter_bytes(analysis[selectedIndex].ipInterfaceStatistics.bytesReceived).unit }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.sent') }}</th>
              <td>{{ numberConversionAdapter_bytes(analysis[selectedIndex].ipInterfaceStatistics.bytesSent).bytes }} {{ numberConversionAdapter_bytes(analysis[selectedIndex].ipInterfaceStatistics.bytesSent).unit }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.incomingPacketsDiscarded') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.incomingPacketsDiscarded }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.incomingPacketsWithErrors') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.incomingPacketsWithErrors }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.outgoingPacketsDiscarded') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.outgoingPacketsDiscarded }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.outgoingPacketsWithErrors') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.outgoingPacketsWithErrors }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.outputQueueLength') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.outputQueueLength }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.unicastPacketsRecieved') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.unicastPacketsReceived }}</td>
            </tr>
            <tr>
              <th>{{ $t('networkAdapterInformationComponent.unicastPacketsSent') }}</th>
              <td>{{ analysis[selectedIndex].ipInterfaceStatistics.unicastPacketsSent }}</td>
            </tr>
          </tbody>
        </table>
      </Sidebar>
    </template>
  </Portlet>
</template>

<script>
// import mixins
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';
// import components
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: 'NetworkAdapterInformation',
  components: {
    Sidebar
  },
  mixins: [
    numberConversionAdapter
  ],
  data () {
    return {
      analysis: null,
      loading: true,
      showSidebar: false,
      selectedIndex: null,
      showInformations: false,
    }
  },
  computed: {
    sidebarAvailable () {
      if (this.showSidebar == false) {
        return false;
      }
      if (this.selectedIndex == null) {
        return false;
      }
      return true;
    }
  },
  created () {
    this.getAnalyseReport();
  },
  methods: {
    openSidebar (index) {
      this.selectedIndex = index;
      this.showSidebar = true;
    },
    closeSidebar () {
      this.showSidebar = false;
      this.selectedIndex = null;
    },
    getAnalyseReport () {
      this.loading = true;
      this.axios.get(`/ComponentEndpoint/AnalyseReport`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.networkInterfaces == null) {
            return;
          }
          this.analysis = response.data.networkInterfaces;
        })
        .finally(() => {
          this.loading = false;
          this.showInformations = false;
        });
    }
  }
}
</script>

<style scoped>
.buttonTD {
  width: 150px;
}
</style>
